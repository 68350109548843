import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'
import Image from "../../../components/image"
import ImageForTwitter from "../../../components/imageForTwitter"
import Review from "../../../components/review"
import Share from "../../../components/share"
import TweetWidget from "../../../components/tweetWidget"
import Campaign from "../../../components/campaign"
import Tag from "../../../components/tag"
import { IoListCircleOutline } from "react-icons/io5";

const ListTop3Result = props  => {
  const tempData  = useStaticQuery(graphql`
    query ListTop3DrinkQuery {
      allContentfulItems {
        edges {
          node {
            brandId
            sizeId
            subbrandId
            typeId
            itemList {
              num
              href
              shopId
              shop
              per
              per_org
              price
              price_org
              sendPrice
              souryou
              txt
              point
              star
              review
              reviewUrl
            }
          }
        }
      }
      allTypesDrinkYaml {
        edges {
          node {
            cid
            description
          }
        }
      }
      allSizesDrinkYaml {
        edges {
          node {
            cid
            description
          }
        }
      }
      allBrandsDrinkYaml {
        edges {
          node {
            cid
            description
          }
        }
      }
      allShopsYaml {
        edges {
          node {
            cid
            description
          }
        }
      }
      allSubbrandsDrinkYaml {
        edges {
          node {
            brand_id
            subbrand_id
            description
            types {
              type_id 
              sizes {
                size_id
                num	
              }
            }
          }
        }
      }  
    }
  `)
  // itemListからtxtがあるものだけを抽出し、allContentfulItems形で格納する。itemList[0]にばらす感じ。
  var allDiscountItems = {}
  allDiscountItems.edges = []

  for (var items of tempData.allContentfulItems.edges)
  {
    for (var item of items.node.itemList){
      var discountItem = {}
      discountItem.node = {
        brandId:items.node.brandId,
        sizeId:items.node.sizeId,
        subbrandId:items.node.subbrandId,
        typeId:items.node.typeId,
      }
      discountItem.node.itemList = []
      discountItem.node.itemList.push(item)
      allDiscountItems.edges.push(discountItem)
    }
  }

  // allDiscountItems.edgesを100ml当たりの価格が安い順に並び替える
  allDiscountItems.edges.sort(function(a,b){
    return a.node.itemList[0].per - b.node.itemList[0].per
  })
  const filterItems = allDiscountItems.edges.filter(function(tempItem){
    if ((props.typeValue === "all") && (props.brandValue === "all") && (props.shopValue === "all")){
        return (tempItem.node.sizeId === props.sizeValue)
    }
    else if ((props.brandValue === "all") && (props.shopValue !== "all")){
        return (tempItem.node.typeId === props.typeValue) && (tempItem.node.sizeId === props.sizeValue) && (tempItem.node.itemList[0].shopId === props.shopValue)
    }
    else if ((props.brandValue !== "all") && (props.shopValue === "all")){
        return (tempItem.node.typeId === props.typeValue) && (tempItem.node.sizeId === props.sizeValue) && (tempItem.node.brandId === props.brandValue) 
    }
    else if ((props.brandValue === "all") && (props.shopValue === "all")){
        return (tempItem.node.typeId === props.typeValue) && (tempItem.node.sizeId === props.sizeValue)
    }
    else{
      return (tempItem.node.typeId === props.typeValue) && (tempItem.node.sizeId === props.sizeValue) && (tempItem.node.brandId === props.brandValue) && (tempItem.node.itemList[0].shopId === props.shopValue)
    }
  })
  const result = filterItems.slice(0,10) // Top10

  let cnt = 0
  let cheap_item0 = {}
  return (
    <div className="columns is-multiline is-gapless">
      {result.map(item => {
        const type = getType(tempData, item.node.typeId)
        const size = getSize(tempData, item.node.sizeId)
        const brand = getBrand(tempData, item.node.brandId)
        const subbrand = getSubbrand(tempData, item.node.brandId, item.node.subbrandId)
        const filename = `${brand.cid}_${type.cid}_${size.cid}_${subbrand.subbrand_id}.jpg`
        const detailurl = `/drink/${type.cid}_${size.cid}_${brand.cid}_${subbrand.subbrand_id}.html`
        const sizeStr = '' 
        const itemStr = 'ドリンク'
        const item0 = item.node.itemList[0]
        const subbrandDetail = subbrand.types.find(n => {
          return n.type_id === type.cid
        }).sizes.find(n => {
          return n.size_id === size.cid
        })
        const pack = item0.num/subbrandDetail.num
        const num = `${subbrandDetail.num}ml×${pack}=${item0.num}ml`

        const sendPrice = item0.sendPrice > 0 ? `送料${item0.sendPrice}円` : item0.sendPrice === 0 ? `送料無料` : item0.sendPrice === -1 ? `実店舗` : '店舗受取';
        cnt += 1
        if(cnt === 1){
          cheap_item0 = item0
        }
        const order = cnt
        let price_org = ``
        if(item0.txt){
          if(item0.txt.match(/OFF/))
          {
            price_org = `${PriceKanma(item0.price_org)}円`
          }
        }
        return (
          <div className="column is-half">
            <a href={`${item0.href}`} rel="nofollow"
              onClick={e => {
                trackCustomEvent({
                  category: `${type.cid}_${size.cid}_${brand.cid}_${subbrand.subbrand_id}`,
                  action: `${item0.shop}`,
                  label: `Top3Aria${order}位`,
                  transport: 'beacon'
                })
              }}									 
            >
              <div className="card">
                <div className="card-content" style={{paddingTop: `0.8rem`, paddingBottom: `0.8rem`, paddingLeft: `0.5rem`, paddingRight: `0.5rem`,}}>
                  <div className="media">
                    <div className="media-left" style={{margin: `0.0rem`}}>
                      { cnt !== 0 && (
                        <span><strong>&nbsp;&nbsp;&nbsp;&nbsp;{order}<span style={{fontSize:`0.9rem`}}>位</span></strong></span>
			                )}
                      <Image filename={filename} alt={`${brand.description}${subbrand.description}${type.description}タイプ${size.description}${sizeStr}の${itemStr}パッケージ画像`}　/>
                      { cnt === 1 && (
                        <ImageForTwitter filename={filename} alt={`${brand.description}${subbrand.description}${type.description}タイプ${size.description}${sizeStr}の${itemStr}パッケージ画像`}　/>
			                )}
                      { cnt === 1 && (
					              <span class="has-text-danger" style={{fontSize:`0.8rem`}}>&nbsp;&nbsp;&nbsp;<strong>最安値</strong></span>
			                )}
                      { cnt === 1 && (
                        <div class="has-text-centered" style={{paddingTop:`0.95rem`, fontSize: `32px`}}>
                        <Link to={detailurl}><span className="icon"><IoListCircleOutline style={{color: `#EB9DA8`}} /></span></Link>
                        </div>
			                )}
                      { cnt !== 1 && (
                        <div class="has-text-centered" style={{paddingTop:`0.72rem`, fontSize: `32px`}}>
                        <Link to={detailurl}><span className="icon"><IoListCircleOutline style={{color: `#EB9DA8`}} /></span></Link>
                        </div>
			                )}
                    </div>
                    <div className="media-content">
                      <div className="content">
                        <p style={{marginBottom: `0.2rem`}}>
                          <span style={{fontSize:`0.7rem`}}><strong>{brand.description} {subbrand.description}</strong></span>
                          <br />
                          <span style={{color: `#ff0000`}} className="is-size-4">&nbsp;{item0.per.toFixed(1)}<span style={{fontSize:`0.7rem`}}> 円/100ml</span></span>
                          <span style={{color: `#ff0000`}} className="is-size-7">&nbsp;&nbsp;{PriceKanma(item0.price)}円<span style={{color: `#808080`}}>&nbsp;<strike>{price_org}</strike></span></span>&nbsp;
                          <Tag item={item0} />
                          <br />
                          { 'point' in item0 && item0.point > 0 && (
                            <><span className="tag is-danger is-light" style={{fontSize:`0.6rem`}}><b>{item0.point}ポイント</b></span>&nbsp;</>
                          )}
                          <span className="tag" style={{fontSize:`0.6rem`}}>{sendPrice}</span>&nbsp;
                          <span className="tag" style={{fontSize:`0.6rem`}}>{num}</span>&nbsp;
                          <br />
                          <span style={{color: `#0783c2`}} className="is-size-7">&nbsp;&nbsp;{item0.shop}</span>
                          <Review item={item0} category={`${type.cid}_${size.cid}_${brand.cid}_${subbrand.subbrand_id}`}/>
                        </p>
                        <Campaign item={item0} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </a>
            { cnt === 1 && (
              <TweetWidget urlPath={`${props.articleUrl}`} />				
            )}
            { cnt === 10 && (
              <Share articleUrl={`${props.articleUrl}`} articleTitle={`【${cheap_item0.per.toFixed(1)}円/100ml】${props.articleTitle}`} />					
            )}
          </div>
        )
      })}
    </div>
  )
}

const ListTop3 = props => {
  return (
    <ListTop3Result typeValue={props.typeValue} sizeValue={props.sizeValue} brandValue={props.brandValue} shopValue={props.shopValue} articleUrl={props.articleUrl} articleTitle={props.articleTitle}/>
  )
}
export default ListTop3


function getType(tempData, cid){
  const ret = tempData.allTypesDrinkYaml.edges.find(edge => {
    return ( edge.node.cid === cid )
  })
  return ret.node
}

function getSize(tempData, cid){
  const ret = tempData.allSizesDrinkYaml.edges.find(edge => {
    return ( edge.node.cid === cid )
  })
  return ret.node
}

function getBrand(tempData, cid){
  const ret = tempData.allBrandsDrinkYaml.edges.find(edge => {
    return ( edge.node.cid === cid )
  })
  return ret.node
}

function getSubbrand(tempData, cid, subid){
  const ret = tempData.allSubbrandsDrinkYaml.edges.find(edge => {
    return ( edge.node.brand_id === cid ) && ( edge.node.subbrand_id === subid )
  })
  return ret.node
}

function PriceKanma(_price){
	var price = _price.toString(10);
	var length = price.length;
	var price_kanma;
	if(length > 3)
	{
		price_kanma = price.slice(0,length-3) + "," + price.slice(length-3);
	}
	else
	{
		price_kanma = price;
	}
	return price_kanma;
}